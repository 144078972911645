import React, { useState } from 'react';
import { ToggleButton } from 'react-bootstrap';
import { object, func } from 'prop-types';

const PreferredCitiesChat = ({ property, openWhatsApp }) => {
  const [radioValue, setRadioValue] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const citiesArray = property.preferred_cities
    ? property.preferred_cities.split(',').map((city) => city.trim())
    : [];

  const itemForSelect = [
    {
      name: 'Sí, Me Gustaría',
      value: 'YES',
      event: () => {
        setDisabledButton(true);
        openWhatsApp(true);
      },
    },
  ];

  return (
    <div className="container-center">
      <div>
        <span style={{ fontSize: 20, fontWeight: 'bold' }}>
          🛑 Principalmente ayudamos a vender propiedades en estos sectores de
          la ciudad👇
        </span>
        <br />
        <br />
        {citiesArray.length > 0 && (
          <ul>
            {citiesArray.map((city) => (
              <li key={city} style={{ fontSize: 18 }}>
                ✓ {city}
              </li>
            ))}
            <li style={{ fontSize: 18 }}>✓ y alrededores</li>
          </ul>
        )}
        <br />
        <span style={{ fontSize: 20, fontWeight: 'bold' }}>
          ¿Te gustaría chatear por WhatsApp con el agente que te ayudará a
          vender tu propiedad en el menor tiempo posible?
        </span>
        <br />
        <br />
        {itemForSelect.map((radio) => (
          <ToggleButton
            key={radio.value}
            type="radio"
            variant="outline-success"
            name="radio"
            value={radio.value}
            disabled={disabledButton}
            className="buttonItem"
            checked={radioValue === radio.value}
            onClick={() => {
              window.fbq('track', 'FormFirstInteraction');
              setRadioValue(radio.value);
              radio.event();
            }}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </div>
    </div>
  );
};

PreferredCitiesChat.propTypes = {
  property: object,
  openWhatsApp: func,
};
PreferredCitiesChat.defaultProps = {
  property: null,
  openWhatsApp: () => {},
};

export default PreferredCitiesChat;
